import { Alert, Button, Slide, Snackbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomText } from "../../components/styled-custom";
import TwitterLogo from "../../assets/icons/twitter-fill.svg";
import FbLogo from "../../assets/icons/facebook.svg";
import InstaLogo from "../../assets/icons/instagram-filled.svg";
import TelegramLogo from "../../assets/icons/telegram.svg";
import NextRound from "../../assets/icons/next-round.svg";
import NextArrow from "../../assets/icons/next-arrow.svg";
import PrevArrow from "../../assets/icons/prev-arrow.svg";
import GalleryImg from "../../components/gallery-img";
import { CustomTextField } from "../../components/custom-text-field";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { AppRepositories } from "../../apis/api-repositories";
import {
  AttachmentDto,
  BannerDto,
  NewsDto,
  ProductDto,
  SocialNetworkDto,
} from "../../apis/general-client";
import { useNavigate } from "react-router-dom";
import { appLoading, currentLanguage } from "../../helpers/utils";
import moment from "moment";
import DefaultImg from "../../assets/images/art1.png"

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [col_1, setCol_1] = useState<any[]>([]);
  const [col_2, setCol_2] = useState<any[]>([]);
  const [col_3, setCol_3] = useState<any[]>([]);
  const [email, setEmail] = useState<string>("");
  const [mobileLoadMore, setLoadMobile] = useState<ProductDto[]>();
  const [welcomeSlide, setWelcomeSilde] = useState<boolean>(false);
  const [artWorkSlide, setArtWork] = useState<boolean>(false);
  const [collectionsSlide, setCollectionsSilde] = useState<boolean>(false);
  const [eventsSlide, setEventsSilde] = useState<boolean>(false);
  const [exhibitionSlide, setExhibitionSilde] = useState<boolean>(false);
  const [videos, setVideos] = useState<AttachmentDto[]>();
  const [collectionsBanner, setCollectionsBanner] = useState<BannerDto[]>();
  const [collectionItems, setCollectionsItems] = useState<ProductDto[]>();
  const [collectionItemsPaging, setCollectionsItemsPaging] =
    useState<ProductDto[]>();
  const [events, setEvents] = useState<NewsDto[]>();
  const [upcomingEvent, setUpcomingEvent] = useState<NewsDto>();
  const [common, setCommon] = useState<any>();
  const [open, setOpenNoti] = useState(false);
  const [currentVideoPage, setCurrentVideoPage] = useState<number>(1);
  const [networks, setNetworks] = useState<SocialNetworkDto[]>();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };
  useEffect(() => {
    window.scrollTo({ top: 1, behavior: "smooth" });
    const fetchData = async () => {
      let val = {
        title: "",
      };
      const videos = await AppRepositories.getVideo();
      const bannerCollection = await AppRepositories.getBannersHome();
      const collectionsItems = await AppRepositories.getCollections();
      const events = await AppRepositories.getEventsSearch(val);
      const common = await AppRepositories.getCommonConfig();
      const networks = await AppRepositories.getSocialNetwork();

      setEvents(events.filter((v) => v.isHomeView));
      setUpcomingEvent(events.filter((v) => v.isHomeView)[0]);
      setCollectionsItems(collectionsItems);
      setCollectionsItemsPaging(collectionsItems.slice(0, 6));
      setNetworks(networks);
      setLoadMobile(collectionsItems.slice(0, 6));
      handleCol(collectionsItems.slice(0, 6));
      setVideos(videos);
      setCollectionsBanner(bannerCollection);
      setCommon(common);
    };

    window.addEventListener("scroll", handleScroll, false);
    fetchData();

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    const destopSpace = [400, 1100, 2000, 3500, 3000];
    const mobileSpace = [5, 1000, 1700, 2700];
    let space = window.innerWidth < 426 ? mobileSpace : destopSpace;
    if (position > space[0]) {
      setWelcomeSilde(true);
    }
    if (position > space[1]) {
      setArtWork(true);
    }
    if (position > space[2]) {
      setCollectionsSilde(true);
    }
    if (position > space[3]) {
      setEventsSilde(true);
    }
    if (position > space[4]) {
      setExhibitionSilde(true);
    }

    if (position < 255) {
      setWelcomeSilde(false);
      setArtWork(false);
      setCollectionsSilde(false);
      setEventsSilde(false);
      setExhibitionSilde(false);
    }
  };

  const handleChangeVideo = (status: string) => {
    if (status) {
      if (status === 'next' && videos) {
        setCurrentVideoPage(currentVideoPage + 1 > videos.length ? videos.length : currentVideoPage + 1);
      } else {
        setCurrentVideoPage(currentVideoPage - 1 === 0 ? 1 : currentVideoPage - 1);
      }
    }
  }

  const handleCol = (items: ProductDto[]) => {
    const _left = [];
    const _center = [];
    const _right = [];
    for (let i = 1; i <= Math.ceil(items.length / 3); i++) {
      const n = i * 3;
      items[n - 3] && _left.push(items[n - 3]);
      items[n - 2] && _center.push(items[n - 2]);
      items[n - 1] && _right.push(items[n - 1]);
    }
    setCol_1(_left);
    setCol_2(_center);
    setCol_3(_right);
  };
  async function onSent() {
    appLoading.show();

    var contact = {
      customerName: "",
      customerPhone: "",
      customerEmail: email,
      content: "",
      attachFile: "",
      receiveStatus: true,
      contactType: 1,
    };
    const resData = await AppRepositories.createContact(contact);
    if (resData) {
      setOpenNoti(true);
      appLoading.dismiss();
    }
    setEmail("");
  }
  const handleLoadMoreCollections = () => {
    if (collectionItemsPaging && collectionItems && mobileLoadMore) {
      setCollectionsItemsPaging(
        collectionItems?.slice(0, collectionItemsPaging?.length + 6)
      );
      handleCol(collectionItems?.slice(0, collectionItemsPaging?.length + 6));
      setLoadMobile(collectionItems?.slice(0, mobileLoadMore?.length + 6));
    }
  };
  const handleClickNetwork = (item: SocialNetworkDto) => {
    if (item && item.linkUrl) {
      window.location.href = item.linkUrl;
    }
  }
  return (
    <div className="home-container">
      <div className="banner">
        <div className="video-swiper">
          <Swiper
            grabCursor={true}
            navigation={{
              nextEl: ".review-swiper-button-next",
              prevEl: ".review-swiper-button-prev",
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {videos &&
              videos.length > 0 &&
              videos.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <video autoPlay muted loop playsInline >
                    <source src={item.fileUrl} type="video/mp4" />
                  </video>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="navigator-img">
            {videos && <div className="paginator-video">
              <CustomText>{currentVideoPage}</CustomText>
              /
              <CustomText>{videos.length}</CustomText>
              </div>}
            <div className="review-swiper-button-prev pointer" onClick={() => handleChangeVideo('prev')}>
              <img src={PrevArrow} alt="prev" />
            </div>
            <div className="review-swiper-button-next pointer" onClick={() => handleChangeVideo('next')}>
              <img src={NextArrow} alt="next" />
            </div>
          </div>
        </div>
        <div className="blur"></div>
        <div className="content">
          <CustomText className="title">
            {currentLanguage.isEN ? common?.titleEn : common?.titleVi}
          </CustomText>
          <CustomText className="textEllipsis">
            {currentLanguage.isEN
              ? common?.descriptionsEn
              : common?.descriptionsVi}
          </CustomText>
          <Button
            onClick={() => {
              navigate("/about-us");
            }}
            variant="contained"
            className="bownYellow"
          >
            {t("viewMore")}
          </Button>
        </div>
        <div className="social-link pt8">
          <CustomText className="white">{t("followUs")}</CustomText>
          <div className="line"></div>
          {networks && networks.length > 0 && networks.map((item: SocialNetworkDto, index: number) => (
            <div key={index} onClick={() => handleClickNetwork(item)} className="pointer">
              <img src={item.imageUrl} alt="social network" />
            </div>
          ))}
          {/* <img src={TwitterLogo} alt="twitter" />
          <img src={FbLogo} alt="facebook" />
          <img src={InstaLogo} alt="instagram" />
          <img src={TelegramLogo} alt="telegram" /> */}
        </div>
      </div>

      <div className="welcome">
        {welcomeSlide && (
          <>
            <Slide
              direction="right"
              in={welcomeSlide}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <div className="left">
                <CustomText className="title">
                  {currentLanguage.isEN ? common?.titleEn : common?.titleVi}
                </CustomText>
                <div
                  className="innerHtml"
                  dangerouslySetInnerHTML={{
                    __html: currentLanguage.isEN
                    ? common?.contentEn
                    : common?.contentVi,
                  }}
                ></div>
                {/* <CustomText className="gray500">
                  {currentLanguage.isEN
                    ? common?.descriptionsEn
                    : common?.descriptionsVi}
                </CustomText> */}
              </div>
            </Slide>
            <Slide
              direction="left"
              in={welcomeSlide}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <div className="right">
                <img src={common?.attachFile} alt="welcome" />
              </div>
            </Slide>
          </>
        )}
      </div>

      <div className="artwork">
        <div>
          <Slide
            direction="up"
            in={artWorkSlide}
            mountOnEnter
            unmountOnExit
            timeout={800}
          >
            <div className="artwork-swiper">
              <div className="">
                <Swiper
                  grabCursor={true}
                  spaceBetween={20}
                  navigation={{
                    nextEl: ".review-swiper-button-next",
                    prevEl: ".review-swiper-button-prev",
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {collectionsBanner &&
                    collectionsBanner.length > 0 &&
                    collectionsBanner.map((item: BannerDto, index: number) => (
                      <SwiperSlide key={index}>
                        <div className="title">
                          {artWorkSlide && (
                            <>
                              <Slide
                                direction="up"
                                in={artWorkSlide}
                                mountOnEnter
                                unmountOnExit
                                timeout={800}
                              >
                                <CustomText className="left">
                                  {currentLanguage.isEN
                                    ? item?.titleEn
                                    : item?.title}
                                </CustomText>
                              </Slide>
                              <Slide
                                direction="up"
                                in={artWorkSlide}
                                mountOnEnter
                                unmountOnExit
                                timeout={800}
                              >
                                <CustomText className="right">
                                  {currentLanguage.isEN
                                    ? item?.descriptionEn
                                    : item?.description}
                                </CustomText>
                              </Slide>
                            </>
                          )}
                        </div>
                        <img
                          className="image-slide"
                          src={item.imageUrl}
                          alt="art"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
              <div className="navigator-img">
                <div className="review-swiper-button-prev">
                  <img src={PrevArrow} alt="prev" />
                </div>
                <div className="review-swiper-button-next">
                  <img src={NextArrow} alt="next" />
                </div>
              </div>
            </div>
          </Slide>

        </div>
      </div>

      <div className="collections">
        {collectionsSlide && collectionItems && collectionItemsPaging && (
          <>
            <Slide
              direction="up"
              in={collectionsSlide}
              mountOnEnter
              unmountOnExit
              timeout={800}
            >
              <div className="title">
                <div className="left">
                  <CustomText className="title-left">
                    {t("collections")}
                  </CustomText>
                  <CustomText className="text-left">
                    {t("collectionsTextTitle")}
                  </CustomText>
                </div>
                <div className="right">
                  <CustomText className="brownYellow">
                    {t("viewAllCollections")}
                  </CustomText>
                  <div
                    className="btn-next"
                    onClick={() => {
                      navigate("/collections");
                    }}
                  >
                    <img src={NextRound} alt="" />
                  </div>
                </div>
              </div>
            </Slide>
            <Slide
              direction="up"
              in={collectionsSlide}
              mountOnEnter
              unmountOnExit
              timeout={800}
            >
              <div className="gallery">
                <div className="collection-destop">
                  <div className="item-content">
                    {col_1.length > 0 &&
                      col_1.map((item: any, index: number) => (
                        <GalleryImg item={item} key={index} />
                      ))}
                  </div>
                  <div className="item-content">
                    {col_2.length > 0 &&
                      col_2.map((item: any, index: number) => (
                        <GalleryImg item={item} key={index} />
                      ))}
                  </div>
                  <div className="item-content">
                    {col_3.length > 0 &&
                      col_3.map((item: any, index: number) => (
                        <GalleryImg item={item} key={index} />
                      ))}
                  </div>
                </div>
                <div className="collections-mobile">
                  {mobileLoadMore &&
                    mobileLoadMore?.length > 0 &&
                    mobileLoadMore?.map((item: any, index: number) => (
                      <GalleryImg item={item} key={index} />
                    ))}
                </div>

                {mobileLoadMore &&
                collectionItemsPaging.length < collectionItems.length &&
                mobileLoadMore.length < collectionItems.length ? (
                  <div className="btn dFlex justifyCenter w100">
                    <Button
                      onClick={handleLoadMoreCollections}
                      variant="contained"
                      className="bownYellow"
                    >
                      {t("loadMore")}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Slide>
          </>
        )}
      </div>
      <div className="events">
        {eventsSlide && (
          <Slide
            direction="up"
            in={eventsSlide}
            mountOnEnter
            unmountOnExit
            timeout={900}
          >
            <div className="title">
              <div className="top">{t("dontMiss")}</div>
              <div className="bottom">{t("upcomingEvents")}</div>
            </div>
          </Slide>
        )}

        <div className="content">
          {upcomingEvent && eventsSlide && (
            <Slide
              direction="right"
              in={eventsSlide}
              mountOnEnter
              unmountOnExit
              timeout={1000}
            >
              <div>
                <Swiper
                  grabCursor={true}
                  pagination={true}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {upcomingEvent.listImages &&
                    upcomingEvent.listImages?.length > 0 &&
                    upcomingEvent.listImages.map((item: any, index: number) => (
                      <SwiperSlide key={index}>
                        <div className="event-swiper">
                          <img src={item.imageUrl} alt="event" />
                        </div>
                      </SwiperSlide>
                    ))}

                  {/* <SwiperSlide>                
                        <div className="event-swiper">
                            <img src={Pic7} alt="event" />
                        </div>
                    </SwiperSlide> */}
                </Swiper>
              </div>
            </Slide>
          )}

          {upcomingEvent && eventsSlide && (
            <div className="event-time">
              <div className="title-event">{currentLanguage.isEN ? upcomingEvent.titleEn : upcomingEvent.titleVi}</div>
              <div className="date">
                {moment(upcomingEvent.fromDate).format("DD MMMM YYYY")} -{" "}
                {moment(upcomingEvent.toDate).format("DD MMMM YYYY")}
              </div>
              <div className="place">{upcomingEvent.location}</div>
            </div>
          )}
        </div>
      </div>

      <div className="all-events">
        <div className="title-container">
          <div className="title">
            <div className="left">
              <CustomText>{t("exhibitionsAndEvents")}</CustomText>
            </div>
            <div className="right">
              <CustomText className="brownYellow">{t("viewAll")}</CustomText>
              <div
                className="btn-next"
                onClick={() => {
                  navigate("/current-events");
                }}
              >
                <img src={NextRound} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="all-event-swiper">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 2.5 : 1}
            spaceBetween={20}
            grabCursor={true}
            className="mySwiper"
          >
            {events &&
              events.length > 0 &&
              events.map((item: NewsDto, index: number) => (
                <SwiperSlide key={index}>
                  <div className="item">
                    <div className="item-img">
                      <img
                        src={
                          item && item.listImages && item.listImages.length > 0
                            ? item.listImages[0].imageUrl
                            : DefaultImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <div className="title-event">{currentLanguage.isEN ? item.titleEn : item.titleVi}</div>
                      <div className="date">
                        {moment(item.fromDate).format("DD MMMM YYYY")} -{" "}
                        {moment(item.toDate).format("DD MMMM YYYY")}
                      </div>
                      <div className="place">{item.location}</div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>

      <div className="contact">
        <div className="contact-container">
          <CustomText className="small-title">{t("contactTitle1")}</CustomText>
          <div className="title">{t("subscribe")}</div>
          <CustomText className="title-2">{t("contactTitle2")}</CustomText>
          <div className="email">
            <CustomTextField
              label=""
              className="contact-subscribe"
              inputProps={{
                value: email,
                placeholder: t("enterMail"),
                error: !/\S+@\S+\.\S+/.test(email),
              }}
              type="text"
              helperText={email != "" ? t("emailInvalid") : ""}
              onChange={(e) => setEmail(e.target.value)}
            ></CustomTextField>
            <div className="button-email">
              <Button
                disabled={email === "" || !/\S+@\S+\.\S+/.test(email)}
                onClick={() => onSent()}
                variant="contained"
                className="bownYellow"
              >
                {t("subscribeBtn")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {t("emailInvalid")}
        </Alert>
      </Snackbar>
    </div>
  );
}
