import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import { CustomTextField } from './custom-text-field';
import SearchIcon from '@mui/icons-material/Search';
import { Select, MenuItem, Collapse, SelectChangeEvent, IconButton } from '@mui/material';
import {
    changeLanguage,
  } from "../helpers/utils";
import JA from '../assets/images/ja-flag.png';
import EN from '../assets/images/en-flag.png';
import Cookies from 'js-cookie';
import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import { CustomText } from './styled-custom';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { AppRepositories } from '../apis/api-repositories';
import { ProductDto } from '../apis/general-client';
import DebouncedValue from './debounced';

export default function HeaderBar(props: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {navFromNavigate} = props;
    const [nav, setNav] = useState<string>('1');
    const [search, setSearch] = useState<string>('');
    const [lang, setLang] = useState<string>(Cookies.get("i18next") || "en");
    const [openEvents, setOpenEvents] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [collectionsItems, setCollectionsItems] = useState<ProductDto[]>();
    const [collectionSearched, setCollectionSearched] = useState<ProductDto[]>();

  const onSelectNav = (value: string) => {
    localStorage.setItem("navSelected", value as unknown as string);
    setNav(value);
  };
  const handleChangeLanguage = (e: SelectChangeEvent<string>) => {
    const targetValue = e.target.value;
    if (targetValue !== lang) {
      setLang(targetValue);
      changeLanguage(targetValue);
    }
  };
  useEffect(() => {
    const nav = localStorage.getItem("navSelected") || "";
    const fetchData = async () => {
      const collectionsItems = await AppRepositories.getCollections();
      setCollectionsItems(collectionsItems);
    }
    fetchData();
    if (navFromNavigate) {
      setNav(navFromNavigate);
    } else {
      setNav(nav);
    }
  }, [navFromNavigate]);

  let debounceValue = DebouncedValue(search, 500);
  useEffect(() => {
    let searched: ProductDto[] = [];
    
    if (collectionsItems && debounceValue) {
        searched = collectionsItems.filter(v => v.productNameEn?.toLowerCase().includes(debounceValue.toLowerCase()));
        setCollectionSearched(searched);
        setOpenSearch(searched.length > 0);
    } else {
      setOpenSearch(false)
    }
   }, [debounceValue])

    const onCollapseMenu = () => {
      setIsChecked((prev) => !prev);
  }

  return (
    <div className="header-bar">
      <div className="header-container">
        <div className="content">
          <div
            className="logo pointer"
            onClick={() => {
              navigate("/");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <img src={Logo} alt="" />
          </div>
          <div className="nav">
            <Link
              to={"/"}
              onClick={() => {
                onSelectNav("1");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "1" ? "selected" : ""}
            >
              {t("home")}
            </Link>
            <Link
              to={"/vr-tour"}
              onClick={() => {
                onSelectNav("2");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "2" ? "selected" : ""}
            >
              {t("vr")}
            </Link>
            <Link
              to={"/collections"}
              onClick={() => {
                onSelectNav("3");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "3" ? "selected" : ""}
            >
              {t("collections")}
            </Link>
            <div
              onClick={() => {
                {
                  onSelectNav("4");
                  setOpenEvents(!openEvents);
                }
              }}
              className={
                nav === "4" ? "selected pointer pl12 pr12" : "pointer pl12 pr12"
              }
            >
              {t("events")}

              <Collapse in={openEvents} orientation="vertical" timeout={300}>
                {openEvents && (
                  <div className="dFlex directionColumn">
                    <Link
                      to={"/current-events"}
                      onClick={() => {
                        onSelectNav("4");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={"event-nav"}
                    >
                      {t("currentEvent")}
                    </Link>
                    <Link
                      to={"/past-exhibitions"}
                      onClick={() => {
                        onSelectNav("4");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={"event-nav"}
                    >
                      {t("pastExhibition")}
                    </Link>
                  </div>
                )}
              </Collapse>
            </div>

            <Link
              to={"/about-us"}
              onClick={() => {
                onSelectNav("5");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "5" ? "selected" : ""}
            >
              {t("aboutUs")}
            </Link>
            <div className="search">
              <CustomTextField
                className="search-input"
                label=""
                iconEnd={<SearchIcon />}
                inputProps={{
                  value: search,
                  placeholder: t("search"),
                }}
                type="text"
                onChange={(e) => setSearch(e.target.value)}
              ></CustomTextField>
              <Collapse in={openSearch} orientation="vertical" timeout={300} >
                {collectionSearched && collectionSearched.length > 0 && collectionSearched.map((item: ProductDto, index: number) => (
                  <div className="collection" key={index} onClick={() => {navigate('/collection-detail', {state: {id: item.id, categoryId: item.brandId}}); setSearch('')}}>
                    {item.productNameEn}
                  </div>
                ))}
              </Collapse>

            </div>
            <Select
              className="language-select"
              value={lang}
              onChange={handleChangeLanguage}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="ja">
                <div className="flag-icon">
                  <img src={JA} alt="JA Flag" className="w100 h100" />
                </div>
              </MenuItem>
              <MenuItem value="en">
                <div className="flag-icon">
                  <img src={EN} alt="EN Flag" className="w100 h100" />
                </div>
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      <div className="header-container-mobile">
        <div
          className="logo pointer"
          onClick={() => {
            navigate("/");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img src={Logo} alt="logo" />
        </div>
        <div className="menu">
        <IconButton aria-label="menu" onClick={onCollapseMenu}>{isChecked ? <ClearIcon /> : <MenuIcon />}</IconButton >
        <Collapse in={isChecked} orientation="horizontal" className='nav-mobile' timeout={300}>
          <div className='nav'>
          <div className="search">
              <CustomTextField
                className="search-input"
                label=""
                iconEnd={<SearchIcon />}
                inputProps={{
                  value: search,
                  placeholder: t("search"),
                }}
                type="text"
                onChange={(e) => setSearch(e.target.value)}
              ></CustomTextField>
              <Collapse in={openSearch} orientation="vertical" timeout={300} >
                {collectionSearched && collectionSearched.length > 0 && collectionSearched.map((item: ProductDto, index: number) => (
                  <div className="collection" key={index} onClick={() => {navigate('/collection-detail', {state: {id: item.id, categoryId: item.brandId}}); setSearch(''); onCollapseMenu()}}>
                    {item.productNameEn}
                  </div>
                ))}
              </Collapse>

            </div>
            <Link
              to={"/"}
              onClick={() => {
                onSelectNav("1");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "1" ? "selected" : ""}
            >
              {t("home")}
            </Link>
            <Link
              to={"/vr-tour"}
              onClick={() => {
                onSelectNav("2");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "2" ? "selected" : ""}
            >
              {t("vr")}
            </Link>
            <Link
              to={"/collections"}
              onClick={() => {
                onSelectNav("3");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "3" ? "selected" : ""}
            >
              {t("collections")}
            </Link>
            <div
              onClick={() => {
                {
                  setOpenEvents(!openEvents);
                }
              }}
              className={nav === "4" ? "selected " : ""}
            >
              <div className='event-item'>
              <CustomText className=''>{t("events")}</CustomText>
              <IconButton aria-label="dropdown" >{openEvents ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}</IconButton >
              </div>
              

              <Collapse in={openEvents} orientation="vertical" timeout={300} className="collapse-event">
                {openEvents && (
                  <div className="dFlex directionColumn">
                    <Link
                      to={"/current-events"}
                      onClick={() => {
                        onSelectNav("4");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={"event-nav upperCase"}
                    >
                      {t("currentEvent")}
                    </Link>
                    <Link
                      to={"/past-exhibitions"}
                      onClick={() => {
                        onSelectNav("4");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={"event-nav upperCase"}
                    >
                      {t("pastExhibition")}
                    </Link>
                  </div>
                )}
              </Collapse>
            </div>
            <Link
              to={"/about-us"}
              onClick={() => {
                onSelectNav("5");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className={nav === "5" ? "selected" : ""}
            >
              {t("aboutUs")}
            </Link>
          </div>
        </Collapse>
        </div>
      </div>
    </div>
  );
}
