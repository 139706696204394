import HeaderBar from "../../components/header-bar";

export default function VRTour() {
  return (
    <div className="vr-tour-container">
      <HeaderBar navFromNavigate={'2'} />
      <iframe
        src="https://vr-demo.grex-solutions.com:8193/"
        title="W3Schools Free Online Web Tutorials"
      ></iframe>
    </div>
  );
}
