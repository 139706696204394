import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import AboutUs from "../views/about-us/about-us";
import CollectionDetail from "../views/collection-detail/collection-detail";
import Collections from "../views/collections/collections";
import CurrentEvents from "../views/events/current-events";
import Events from "../views/events/events";
import PastExhibitions from "../views/events/past-exhibitions";
import VRTour from "../views/vr-tour/vr-tour";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/vr-tour" element={<VRTour />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/events" element={<Events />} />
        <Route path="/current-events" element={<CurrentEvents />} />
        <Route path="/past-exhibitions" element={<PastExhibitions />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/collection-detail/:id" element={<CollectionDetail />} />

      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
