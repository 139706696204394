import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import Footer from "../../components/footer";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import Banner from "../../assets/images/art1.png";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import Pic7 from "../../assets/images/pic7.png";
import Pic11 from "../../assets/images/pic11.png";
import Pic12 from "../../assets/images/pic12.png";
import Pic13 from "../../assets/images/pic13.png";
import Pic10 from "../../assets/images/pic10.png";
import { Button, Container, Grid } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Scroll from "../../components/scrollTop";
import { AppRepositories } from "../../apis/api-repositories";
import { NewsDto } from "../../apis/general-client";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { currentLanguage } from "../../helpers/utils";

function CurrentEvents() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [events, setEvents] = useState<NewsDto[]>();
  const [upcomingEvent, setUpcomingEvent] = useState<NewsDto>();
  const [masterData, setMasterData] = useState<any[]>();
  const [currentEvent, setCurrentEvents] = useState<NewsDto[]>();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = async () => {
      let val = {
        title: ""
      };
      const events = await AppRepositories.getEventsSearch(val);

      const currentEvent = events.filter((v) => {
        if (v.fromDate && v.toDate && v.isActived) {
          return (
            new Date(v.fromDate) <= new Date() &&
            new Date() <= new Date(v.toDate)
          );
        }
        return false;
      });
      setCurrentEvents(currentEvent);
      setEvents(events.filter((v) => {
        if (v.fromDate && v.toDate) {
          return (
            new Date() <= new Date(v.fromDate)
          );
        }
        return false;
      }
      ));
    };

    fetchData();
  }, []);

  

  return (
    <>
      <Scroll showBelow={350}></Scroll>
      <HeaderBar navFromNavigate={'4'} />
      <div className="events-container">
        <div className="banner">
          <img src={Banner} alt="" />
          <div className="blur"></div>
          <div className="content">
            <div className="content-container">
              <CustomText className="title">{t("events")}</CustomText>
              <div className="breadcrumb">
                <CustomText
                  className="home"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {t("home")}
                </CustomText>
                <NavigateNextOutlinedIcon className="white" />
                <CustomText>{t("events")}</CustomText>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="current-event">
            <CustomText className="title">{t("currentEvent")}</CustomText>
            {currentEvent && currentEvent.length >0 && (
              <div className="event-container">
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <div className="event-current">
                      <div className="carousel-component-container">
                        <Carousel
                          className="carousel-component"
                          indicatorContainerProps={{
                            className: "carousel-indicator",
                          }}
                          indicators={false}
                          navButtonsAlwaysInvisible={true}
                          height={window.innerWidth < 500 ? 280 : 580}
                        >
                          {currentEvent && currentEvent.length > 0 &&currentEvent[0].listImages?.map((item, index) => (
                            <div className="grid-event" key={index}>
                                <div className="event-swiper" key={index}>
                                  <img
                                    src={item.imageUrl}
                                    alt={`CURRENT EVENT ${index}`}
                                  />
                                </div>
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className="event-time">
                      <div className="title-event">{currentLanguage.isEN ? currentEvent[0].titleEn : currentEvent[0].titleVi}</div>
                      <div className="date">
                        {moment(currentEvent[0].fromDate).format("DD MMMM")} - {moment(currentEvent[0].toDate).format("DD MMMM YYYY")}
                      </div>
                      <div className="place">{currentEvent[0].location}</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>

          <div className="upcoming">
            <div className="title">
              <CustomText className="left">
                {t("upcomingExhibitionEvents")}
              </CustomText>
              <CustomText className="right">
                {t("upcomingTextTitle")}
              </CustomText>
            </div>

            <div className="all-event">
              {events &&
                events.length > 0 &&
                events.map((item: NewsDto, index: number) => (
                  <div className="event" key={index}>
                    {item.listImages && (
                      <div className="img">
                        <img src={item && item.listImages && item.listImages.length > 0 ? item.listImages[0].imageUrl : Banner} alt="" />
                      </div>
                    )}

                    <div className="blur"></div>
                    <div className="description">
                      <CustomText className="title-event">
                        {currentLanguage.isEN ? item.titleEn : item.titleVi}
                      </CustomText>
                      <div className="time">
                        {item.location && (
                          <>
                            <CustomText className="pr12">
                              {item.location}
                            </CustomText>
                            <FiberManualRecordIcon className="white pr12" />
                          </>
                        )}
                        <CustomText>
                          {moment(item.fromDate).format("DD MMMM")} - {moment(item.toDate).format("DD MMMM YYYY")}
                        </CustomText>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CurrentEvents;
