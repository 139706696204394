import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import Banner from "../../assets/images/pic14.png";
import Pic15 from "../../assets/images/pic15.png";
import Scroll from "../../components/scrollTop";
import { AppRepositories } from "../../apis/api-repositories";
import { BannerDto } from "../../apis/general-client";
import { currentLanguage } from "../../helpers/utils";

function AboutUs() {
  const { t } = useTranslation();
  const [common, setCommon] = useState<any>();

  const bannerCollection = async () => {
    const common = await AppRepositories.getCommonAboutUs();
    setCommon(common);
    
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    bannerCollection();
  },[]);

  return (
    <>
      <Scroll showBelow={350}></Scroll>
      <HeaderBar navFromNavigate={'5'} />
      <div className="about-us-container">
        <div className="banner">
          <img src={common?.attachFile} alt="" />
          <div className="blur"></div>
          <div className="content">
            <div className="content-container">
              <CustomText className="title">
                {currentLanguage.isEN ? common?.titleEn : common?.titleVi}
              </CustomText>
              <CustomText className="banner-content textEllipsis">
                {currentLanguage.isEN
                  ? common?.descriptionsEn
                  : common?.descriptionsVi}
              </CustomText>
            </div>
          </div>
        </div>

        <div className="about-content">
          <div className="left">
            <CustomText className="title">{t("about")}</CustomText>
            <div
              className="innerHtml"
              dangerouslySetInnerHTML={{
                __html: currentLanguage.isEN
                ? common?.contentEn
                : common?.contentVi,
              }}
            ></div>
            {/* <CustomText>
              {currentLanguage.isEN
                ? common?.descriptionsEn
                : common?.descriptionsVi}
            </CustomText> */}
          </div>
          <div className="right">
            <img src={common?.attachFile2} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
