import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import Footer from "../../components/footer";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import Banner from "../../assets/images/art1.png";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Scroll from "../../components/scrollTop";
import { AppRepositories } from "../../apis/api-repositories";
import { NewsDto } from "../../apis/general-client";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { currentLanguage } from "../../helpers/utils";


function PastExhibitions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [events, setEvents] = useState<NewsDto[]>();
  const today = new Date();
  const [pastEvent, setPastEvents] = useState<NewsDto[]>();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = async () => {
      let val = {
        title: ""
      };
      const events = await AppRepositories.getEventsSearch(val);

      const currentEvent = events.filter((v) => {
        if (v.fromDate && v.toDate && v.isActived) {
          return (
            new Date(v.toDate) < new Date(today.setHours(0)) 
          );
        } else {
          return false;
        }
      });

      setPastEvents(currentEvent);
    };

    fetchData();
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
    <>
      <Scroll showBelow={350}></Scroll>
      <HeaderBar navFromNavigate={'4'} />
      <div className="events-container">
        {windowSize.innerWidth > 426 && <div className="banner">
          <img src={Banner} alt="" />
          <div className="blur"></div>
          <div className="content">
            <div className="content-container">
              <CustomText className="title">{t("events")}</CustomText>
              <div className="breadcrumb">
                <CustomText
                  className="home"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {t("home")}
                </CustomText>
                <NavigateNextOutlinedIcon className="white" />
                <CustomText>{t("events")}</CustomText>
              </div>
            </div>
          </div>
        </div>}
        <div className="content">
          <div className="upcoming">
            <div className="past-event">
              <div className="title">
                <CustomText className="left capitalizeCase">{t("pastExhibition")}</CustomText>
              </div>
              {pastEvent && (
                <div className="all-event">
                  {pastEvent.map((item: any, index: number) => (
                    <div className="event" key={index}>
                       {item.listImages && (
                      <div className="img">
                        <img src={item && item.listImages && item.listImages.length > 0 ? item.listImages[0].imageUrl : Banner} alt="" />
                      </div>
                    )}
                      {/* <div className="img">
                        <img src={item.img} alt="" />
                      </div> */}
                      <div className="blur"></div>
                      <div className="description">
                        <CustomText className="title-event">
                          {currentLanguage.isEN ? item.titleEn : item.titleVi}
                        </CustomText>
                        <div className="time">
                          <CustomText className="pr12">{item.location}</CustomText>
                          <FiberManualRecordIcon className="white" />
                          <CustomText className="pl12"> {moment(item.fromDate).format("DD MMMM")} - {moment(item.toDate).format("DD MMMM YYYY")} </CustomText>
                          
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PastExhibitions;
