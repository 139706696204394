import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer';
import HeaderBar from '../../components/header-bar';
import { CustomText } from '../../components/styled-custom';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import Banner from '../../assets/images/art1.png'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import Pic7 from '../../assets/images/pic7.png';
import Pic11 from '../../assets/images/pic11.png';
import Pic12 from '../../assets/images/pic12.png';
import Pic13 from '../../assets/images/pic13.png';
import Pic10 from '../../assets/images/pic10.png';
import { Button } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Scroll from '../../components/scrollTop';
import { AppRepositories } from '../../apis/api-repositories';
import { NewsDto } from '../../apis/general-client';

const dataEvents = [
    {
        img: Pic10,
        title: 'Marcello Lo Giudice | Colours of Eden',
        date: "16 June - 06 July 2022",
        place: 'Sài Gòn'
    },
    {
        img: Pic11,
        title: 'Marcello Lo Giudice | Colours of Eden',
        date: "16 June - 06 July 2022",
        place: 'Sài Gòn'
    },
    {
        img: Pic12,
        title: 'Marcello Lo Giudice | Colours of Eden',
        date: "16 June - 06 July 2022",
        place: 'Sài Gòn'
    },
    {
        img: Pic13,
        title: 'Marcello Lo Giudice | Colours of Eden',
        date: "16 June - 06 July 2022",
        place: 'Sài Gòn'
    },
]

function Events() {
    const { t } = useTranslation();
    const [events, setEvents] = useState<NewsDto[]>();
    const [upcomingEvent, setUpcomingEvent] = useState<NewsDto>();

    useEffect(() => {
        const fetchData =async () => {
            const events = await AppRepositories.getEvents();
            setEvents(events.filter(v => v.isHomeView));
            setUpcomingEvent(events.filter(v => v.isHomeView)[0])
        }

        fetchData();

    }, []);
    
  return (
    <>
    <Scroll showBelow={350}></Scroll>
    {/* <HeaderBar navFromNavigate={'4'} /> */}
    <div className="events-container">

    </div>
    <Footer />
    </>
  )
}

export default Events