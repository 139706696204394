import { generalClient } from "../helpers/utils";
import { AttachmentType, BannerTypesEnum } from "./general-client";

const AppRepositories = {
  getVideo: async () => {
    const res = await generalClient.attachments_GetAttachmentsByType(
      AttachmentType.Video
    );
    return res;
  },

  getBannersHome: async () => {
    const res = await generalClient.banner_GetBannerByType(
      BannerTypesEnum.BannerHomePage
    );
    return res;
  },

  getCollections: async () => {
    const res = await generalClient.product_GetAll();
    return res;
  },

  getEvents: async () => {
    const res = await generalClient.news_GetNews();
    return res;
  },

  getEventsSearch: async (model: any) => {
    const res = await generalClient.news_SearchingNews(model);
    return res;
  },

  getBrandCollections: async () => {
    const res = await generalClient.brand_GetAll();
    return res;
  },

  getCommonConfig: async () => {
    const res= await generalClient.commonConfig_GetCommonConfigByType(2)
    return res
  },
  getCommonAboutUs: async () => {
    const res = await generalClient.commonConfig_GetCommonConfigByType(1);
    return res;
  },

  createContact: async (model: any) => {
    const res = await generalClient.contactCustomer_PostNew(model);
    return res;
  },

  getEvent: async () => {
    const res = await generalClient.newCategories_GetNewCategories();
    return res;
  },

  getCollectionById:async (id:string) => {
    const res = await generalClient.product_GetProductById(id);
    return res;
  },

  getSocialNetwork:async () => {
    const res = await generalClient.socialNetwork_GetSocialNetworks();
    return res;
  }

};

export { AppRepositories };
