import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ProductDto } from '../apis/general-client';
import { currentLanguage } from '../helpers/utils';

export default function GalleryImg(props: any) {
    const {item} = props;
    const navigate = useNavigate();

    const handleClickCollection = (item: ProductDto) => {
        navigate('/collection-detail/' + item.id, {state: {id: item.id, categoryId: item.brandId}})
    }
      
    return (
        <div className='item-container pointer' onClick={() => handleClickCollection(item)}>
            <div className='pic'>
                <img src={item.imageUrl} alt="" />
            </div>
            <div className="content">
                <div className="title-img">{currentLanguage.isEN ? item.productNameEn : item.productNameVi}</div>
                <div className="description">{currentLanguage.isEN ? item.productCategory.productCategoryNameEn : item.productCategory.productCategoryNameVi}, {item.brand.brandName}</div>
            </div>
        </div>
    )
}
