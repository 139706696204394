import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/images/logo.png'
import { CustomText } from './styled-custom';
import { ReactComponent as PhoneIcon } from "../assets/icons/carbon_phone.svg";
import { ReactComponent as MailIcon } from "../assets/icons/ic_outline-email.svg";
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
  return (

    <div className="footer">
    <div className="footer-container">
        <div className="content">
            <div onClick={() => {
              navigate("/");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }} className="logo pointer">
                <img src={Logo} alt="logo" />
            </div>
                <div className="content-list">
                    <div className="item">
                        <Link to={"/vr-tour"}>
                            <CustomText className='bold'>{t('vr')}</CustomText>
                        </Link>
                    </div>
                    <div className="item">
                        <Link to={"/collections"}>
                        <CustomText className='bold'>{t('collections')}</CustomText>
                        </Link>
                        <CustomText>{t('all')}</CustomText>
                        <CustomText>{t('nature')}</CustomText>
                        <CustomText>{t('portrait')}</CustomText>
                        <CustomText>{t('religious')}</CustomText>
                    </div>
                    <div className="item">
                        <Link to={"/current-events"}>
                        <CustomText className='bold'>{t('events')}</CustomText>
                        </Link>
                        <Link to={"/current-events"}>
                        <CustomText className='capitalizeCase'>{t('currentEvent')}</CustomText>
                        </Link>
                        <Link to={"/past-exhibitions"}>
                        <CustomText className='capitalizeCase'>{t('pastExhibition')}</CustomText>
                        </Link>
                    </div>
                    <div className="item">
                    <Link to={"/about-us"}>
                        <CustomText className='bold'>{t('aboutUs')}</CustomText>
                        </Link>
                    </div>
                </div>
        </div>
        <div className="contact-info">
            <CustomText className='bold'>{t('contactUs')}</CustomText>
            <div className='icon'>
                <PhoneIcon />
                <CustomText>+84 989093979</CustomText>
            </div>
            <div className='icon'>
                <MailIcon />
                <CustomText>infowilliamart@gmail.com</CustomText>
            </div>
        </div>
    </div>

    </div>
  )
}

export default Footer