import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer';
import HeaderBar from '../../components/header-bar';
import Banner from '../../assets/images/collections-banner.png'
import { CustomText } from '../../components/styled-custom';
import { useTranslation } from 'react-i18next';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import GalleryImg from '../../components/gallery-img';
import { Button } from '@mui/material';
import Scroll from '../../components/scrollTop';
import { BrandDto, ProductDto } from '../../apis/general-client';
import { AppRepositories } from '../../apis/api-repositories';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css";
import { FreeMode } from "swiper";
import { useNavigate } from 'react-router-dom';

function Collections() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [col_1, setCol_1] = useState<any[]>([]);
    const [col_2, setCol_2] = useState<any[]>([]);
    const [col_3, setCol_3] = useState<any[]>([]);
    const [selected, setSelected] = useState<number>(1);
    const [collectionItems, setCollectionsItems] = useState<ProductDto[]>();
    const [collectionItemsPaging, setCollectionsItemsPaging] = useState<ProductDto[]>();
    const [collectionItemsCategory, setCollectionsItemsCategory] = useState<ProductDto[]>();
    const [categories, setCategories] = useState<BrandDto[]>();
    const [selectedCategories, setSelectedCategories] = useState<string>();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        
        const fetchData =async () => {
            const collectionsItems = await AppRepositories.getCollections();
            const categories = await AppRepositories.getBrandCollections();
            setCategories(categories);
            setCollectionsItems(collectionsItems);
            setCollectionsItemsCategory(collectionsItems);
            setCollectionsItemsPaging(collectionsItems.slice(0, 6));
            handleCol(collectionsItems.slice(0, 6));
        }
        fetchData();
    }, []);

    const handleCol = (items: ProductDto[]) => {
        const _left = [];
        const _center = [];
        const _right = [];
        for (let i = 1; i <= Math.ceil(items.length / 3); i++) {
            const n = i * 3;
            items[n - 3] && _left.push(items[n - 3]);
            items[n - 2] && _center.push(items[n - 2]);
            items[n - 1] && _right.push(items[n - 1]);
        }
        setCol_1(_left);
        setCol_2(_center);
        setCol_3(_right);
    }  

    const handleLoadMoreCollections = () => {
        if (collectionItemsPaging && collectionItems) {
            const filter = selectedCategories ? 
            collectionItems?.filter(v => v.brandId === selectedCategories).slice(0, collectionItemsPaging?.length + 6) : 
            collectionItems?.slice(0, collectionItemsPaging?.length + 6);
            setCollectionsItemsPaging(filter);
            handleCol(filter);
        }
    } 

    const handleChangeCategories = (id: string | undefined) => {
        setSelectedCategories(id);
        if (collectionItemsPaging && collectionItems) {
            const filter = id ? collectionItems.filter(v => v.brandId === id).slice(0, 6) : collectionItems.slice(0, 6);
            setCollectionsItemsPaging(filter);
            if (id) {
                setCollectionsItemsCategory(collectionItems.filter(v => v.brandId === id));
            } else {
                setCollectionsItemsCategory(collectionItems);
            }
            handleCol(filter);
        }
    }

  return (
    <>
    <Scroll showBelow={350}></Scroll>
    <HeaderBar navFromNavigate={'3'} />
    <div className="collections-container">
        <div className="banner">
            <img src={Banner} alt="" />
            <div className="blur"></div>
            <div className="content">
                <div className="content-container">
                <CustomText className='title'>{t('collections')}</CustomText>
                <div className="breadcrumb">
                    <CustomText
                        className="home"
                        onClick={() => {
                        navigate("/");
                        }}
                    >
                        {t("home")}
                    </CustomText>
                    <NavigateNextOutlinedIcon className='white' />
                    <CustomText>{t('collections')}</CustomText>
                </div>

                </div>
            </div>
        </div>

        <div className="gallerys">
            {collectionItemsPaging && collectionItems && collectionItemsCategory && 
                <>
                    <div className="categorys">
                        <Button onClick={() => {setSelected(1); handleChangeCategories('')}} variant='outlined' className={selected === 1 ? 'selected' : ''}>{t('all')}</Button>
                        {categories && categories.length > 0 && categories.map((item: BrandDto, index: number) => (
                            <Button key={index} onClick={() => {setSelected(index + 2); handleChangeCategories(item.id)}} variant='outlined' className={selected === index + 2 ? 'selected' : ''}>{item.brandName}</Button>

                        ))}
                        {/* <Button onClick={() => setSelected(3)} variant='outlined' className={selected === 3 ? 'selected' : ''}>{t('portrait')}</Button>
                        <Button onClick={() => setSelected(4)} variant='outlined' className={selected === 4 ? 'selected' : ''}>{t('religious')}</Button> */}
                    </div>
                    <div className="gallery">
                        <div className='item-content'>
                            {col_1.length > 0 && col_1.map((item: any, index: number) => (
                                <GalleryImg item={item} key={index} />
                            ))}
                        </div>
                        <div className='item-content'>
                            {col_2.length > 0 && col_2.map((item: any, index: number) => (
                                <GalleryImg item={item} key={index} />
                            ))}
                        </div>
                        <div className='item-content'>
                            {col_3.length > 0 && col_3.map((item: any, index: number) => (
                                <GalleryImg item={item} key={index} />
                            ))}
                        </div>
                        {(collectionItemsPaging.length < collectionItemsCategory.length) ? <div className="btn dFlex justifyCenter w100">
                            <Button onClick={handleLoadMoreCollections} variant='contained' className='bownYellow'>{t('loadMore')}</Button>
                        </div> : <></>}
                    </div>
                </>
            }
        </div>
        
        <div className="gallerys-mobile">
            {collectionItemsPaging && collectionItems && collectionItemsCategory && <>
            <div className='categorys'>
                <Swiper
                    slidesPerView={2.5}
                    spaceBetween={20}
                    freeMode={true}
                    modules={[FreeMode]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                    <Button onClick={() => {setSelected(1); handleChangeCategories('')}} variant='outlined' className={selected === 1 ? 'selected' : ''}>{t('all')}</Button>
                    </SwiperSlide>
                    {categories && categories.length > 0 && categories.map((item: BrandDto, index: number) => (
                        <SwiperSlide key={index}>
                            <Button
                                onClick={() => { setSelected(index + 2); handleChangeCategories(item.id) }}
                                variant='outlined'
                                className={selected === index + 2 ? 'selected' : ''}>
                                {item.brandName}
                            </Button>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>
            <div className="gallery">
                {collectionItemsCategory.map((item: ProductDto, index: number) => (
                    <GalleryImg item={item} key={index} />
                ))}
            </div>
            </>}
        </div>
    </div>
    <Footer />
    </>
  )
}

export default Collections