import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomText } from '../../components/styled-custom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Footer from '../../components/footer';
import HeaderBar from '../../components/header-bar';
import Scroll from '../../components/scrollTop';
import { AppRepositories } from '../../apis/api-repositories';
import { ProductDto } from '../../apis/general-client';
import { currentLanguage, shuffleArray } from '../../helpers/utils';
import {ReactComponent as TwitterIcon} from '../../assets/icons/twitter-fill-black.svg';
import {ReactComponent as TelegramIcon} from '../../assets/icons/telegram-black.svg';
import {ReactComponent as InstagramIcon} from '../../assets/icons/instagram-filled-black.svg';
import {ReactComponent as FacebookIcon} from '../../assets/icons/facebook-black.svg';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton } from "react-share";
import moment from 'moment';

function CollectionDetail() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    // const location = useLocation() as any;
    const [detail, setDetail] = useState<ProductDto>();
    // const [categories, setCategories] = useState<ProductDto[]>();
    const [alsoLike, setAlsoLike] = useState<ProductDto[]>();
    const [allProduct, setAllProduct] = useState<ProductDto[]>();
    // const id = location.state && location.state.id ? location.state.id : '';
    // const categoriesId = location.state && location.state.categoryId ? location.state.categoryId : '';

    useEffect(() => {
      const fetchData = async () => {
        if (id) {
          const res = await AppRepositories.getCollectionById(id);
          const allProduct = await AppRepositories.getCollections();
          if (res && allProduct) {
            // setCategories(allProduct.filter(v => v.brandId === categoriesId));
            setAllProduct(allProduct);
            let temp = allProduct.filter(v => v.id !== id);
            let tempShuffle = shuffleArray([...temp]);
            setAlsoLike(tempShuffle);
            setDetail(res);
          }
        }
      }
      fetchData()
    }, [id]);

    const handleChangeCollection = async (item: ProductDto) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (item && item.id && allProduct) {
        const res = await AppRepositories.getCollectionById(item.id);
        setDetail(res);
        let temp = allProduct.filter(v => v.id !== item.id);
        let tempShuffle = shuffleArray([...temp]);
        setAlsoLike(tempShuffle);
      }
    }

  return (
    <>
    <Scroll showBelow={350}></Scroll>
    <HeaderBar navFromNavigate={'3'} />
      {detail && <div className="detail-container">
        <div className="breadcrumb">
          <Link
            to={"/collections"}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {t("collections")}
          </Link>
          <NavigateNextIcon />
          <CustomText>{t('details')}</CustomText>
        </div>
        <div className="content">
          <div className="left">
            <div className="pic">
              <img src={detail.imageUrl} alt="pic" />
            </div>
            {(<div
              className="des"
              dangerouslySetInnerHTML={{
                __html:
                  detail?.contentEn && currentLanguage.isEN
                    ? detail.contentEn
                    : detail?.contentVi ?? "",
              }}
            ></div>)}
          </div>
          <div className="right">
            <div className="info">
              <div className="name">{currentLanguage.isEN ? detail.productNameEn : detail.productNameVi}</div>
              <div className="short-des">{currentLanguage.isEN ? detail.descriptionsEn : detail.descriptionsVi}</div>
              <div className="date">
                <CustomText className='bold'>{t('date')}</CustomText>
                {moment(detail.releaseDate).format("DD MMMM YYYY")}
                </div>
              <div className="dimensions pt20">
                <CustomText className='bold'>{t('dimensions')}</CustomText>
                {detail.dimensions}
                </div>
            </div>
            <div className="share">
              <CustomText>{t('share')}</CustomText>
              <TwitterShareButton url={window.location.href}><TwitterIcon /></TwitterShareButton>
              <TelegramShareButton url={window.location.href}><TelegramIcon /></TelegramShareButton>
              
              <FacebookShareButton url={window.location.href}><FacebookIcon  /></FacebookShareButton>
            </div>
          </div>
        </div>
        <div className="categories">
          <div className="title">
            <CustomText>{t('alsoLike')}</CustomText>
          </div>
          <div className="list">
            {alsoLike && alsoLike.slice(0, 3).map((item: ProductDto, index: number) => (
              <div className="item pointer" key={index} onClick={() => handleChangeCollection(item)}>
                <img src={item.imageUrl} alt="img" />
                <CustomText>{currentLanguage.isEN ? item.productNameEn : item.productNameVi}</CustomText>
                <CustomText>{currentLanguage.isEN ?  item.productCategory?.productCategoryNameEn :  item.productCategory?.productCategoryNameVi}, {item.brand?.brandName}</CustomText>
              </div>
              ))
            }
          </div>
        </div>
      </div>}
    <Footer />
    </>
  )
}

export default CollectionDetail