import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "./components/footer";
import HeaderBar from "./components/header-bar";
import Scroll from "./components/scrollTop";
import { AppContext } from "./context/app-context";
import { AppActionStates, appSetState } from "./context/states";
import { changeLanguage } from "./helpers/utils";
import HomePage from "./views/home/home";

function App() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);

  const onChange = () => {
    dispatch(appSetState(AppActionStates.LOGGED, !state.isLogged));
  };

  return (
    <div className="App">
      <Scroll showBelow={350}></Scroll>
      <HeaderBar navFromNavigate={'1'} />
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
